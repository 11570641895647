<template>
  <div class="blogbox">
    <div class="center">
      <div
        class="title point"
        v-for="item in listdata"
        :key="item.id"
        @click="godetail(item)">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { getNewdataApi } from "../../network/api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      listdata: [],
    };
  },
  created() {
    this.getlistdata();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    async getlistdata() {
      let params = {
        user_id: this.userInfo.id ? this.userInfo.id : "",
      };
      const res = await getNewdataApi(params);
      this.listdata = res.data.Items;
    },
    godetail(v) {
      this.$router.push({
        path: `/Blog/${v.keywords}`,
        //  query: { id: v.id }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.blogbox {
  width: 100%;
  min-height: 70vh;
  .center {
    width: 15rem;
    margin: 0 auto;
    .title {
      color: #6ec1e4;
      font-size: 0.36rem;
      text-transform: inherit;
      font-style: inherit;
      text-decoration: inherit;
      font-weight: 600;
      margin-top: 0.6rem;
    }
  }
}
</style>
